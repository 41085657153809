import { useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';
import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';
import support from 'bloko/common/supports';

import { BannerTriggeredData } from 'src/components/EmployerSearch/ContactFeedbackBanner/types';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

interface UseBannerPropsParams {
    resumeHash: string;
    topicId?: string;
    vacancyId?: number;
    shouldShowBanner?: boolean;
    isAllowedPage?: boolean;
}

export const useBannerProps = (params: UseBannerPropsParams): [boolean, (() => void) | undefined] => {
    const { resumeHash, topicId, vacancyId, shouldShowBanner, isAllowedPage } = params;
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = userType === UserType.Employer;
    const allowedTriggerBanner = isEmployer && !!isAllowedPage;
    const shouldRenderBanner = !!shouldShowBanner && allowedTriggerBanner;

    const isStorageCleared = LocalStorageWrapper.getItem('cfb_storage_cleared');
    if (!isStorageCleared && support.localStorage()) {
        // Фронтовый эксп с localStorage отключен, новые строчки добавляться не будут.
        // Чистим оставшиеся записи.
        for (const key in localStorage) {
            if (key.includes('cfb_')) {
                LocalStorageWrapper.removeItem(key);
            }
        }

        LocalStorageWrapper.setItem('cfb_storage_cleared', 'true');
    }

    const handleContactClick = useCallback(() => {
        const params: BannerTriggeredData = {
            resumeHash,
            topicId: Number(topicId) || undefined,
            vacancyId,
        };
        Analytics.sendHHEvent('activityBannerTriggeredByChat', params);
    }, [resumeHash, topicId, vacancyId]);

    return [shouldRenderBanner, allowedTriggerBanner ? handleContactClick : undefined];
};
