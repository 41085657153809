import { ReactNode, useCallback, useMemo } from 'react';

import {
    createDataProvider,
    createStaticDataFetcher,
    decorateWithResultProducer,
    StaticDataFetcherItem,
    DataSection,
} from '@hh.ru/magritte-common-data-provider';
import { Banner, Button, Card, Text } from '@hh.ru/magritte-ui';
import { Select } from '@hh.ru/magritte-ui-select';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { HiringManagerInfo } from 'src/components/HiringManager/types';
import { getIsManagerMCP } from 'src/components/HiringManager/utils/getIsManagerMCP';
import SelectFixedHeight from 'src/components/VacancyFunnel/SelectFixedHeight';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ManagerType } from 'src/models/employerManagers.types';
import { ManagersList } from 'src/models/employerManagersPage';

const TrlKeys = {
    selectPlaceholder: 'employer.vacancy.share.modal.selectPlaceholder',
    applyButton: 'employer.hiringManager.suggest.applyButton',
    mcp: 'employer.hiringManager.mcp',
    nonMcp: 'employer.hiringManager.nonMcp',
    hiringManager: 'employer.hiringManager.hiringManager',
    noOptions: 'employer.hiringManager.suggest.noOptions',
};

interface HiringManagerSuggestProps {
    name: string;
    hiringManagers: HiringManagerInfo[];
    activeManagers: ManagersList[];
    isLoading: boolean;
    onChange: (value: string[]) => void;
    value: string[];
    setSelectedManagers: (managers: ManagersList[]) => void;
    error?: string;
    isInvalid?: boolean;
    description?: ReactNode;
    filterHiringManagers?: boolean;
}

enum ManagerEnum {
    Hiring,
    NonMcr,
    Mcr,
}

interface ManagerItem extends StaticDataFetcherItem {
    enum: ManagerEnum;
}

const HiringManagerSuggest: TranslatedComponent<HiringManagerSuggestProps> = ({
    trls,
    onChange,
    name,
    value,
    setSelectedManagers,
    error,
    isInvalid,
    description,
    filterHiringManagers = false,
    activeManagers,
    hiringManagers,
    isLoading,
}) => {
    const currentManagerId = useSelector((state) => state.employerManager?.id);

    const { provider, items } = useMemo(() => {
        const items: ManagerItem[] = activeManagers.reduce((acc, manager) => {
            const isManagerExcluded = filterHiringManagers
                ? hiringManagers.length && hiringManagers.find((hm) => hm.employerManagerId === manager.id)
                : manager.id.toString() === currentManagerId;
            if (isManagerExcluded) {
                return acc;
            }

            let managerEnum: ManagerEnum = getIsManagerMCP(manager) ? ManagerEnum.Mcr : ManagerEnum.NonMcr;
            if (manager.type === ManagerType.HiringManager) {
                managerEnum = ManagerEnum.Hiring;
            }
            acc.push({ text: manager.fullName, value: manager.id.toString(), enum: managerEnum });
            return acc;
        }, [] as Array<ManagerItem>);
        const provider = createDataProvider({
            minCharsCount: 0,
            debounceTimeout: 0,
            fetcher: decorateWithResultProducer(createStaticDataFetcher(items), (fetcherData) => {
                const sections: DataSection<ManagerItem>[] = [];
                const hiringSection: DataSection<ManagerItem> = { type: 'cells', items: [] };
                const nonMcrSection: DataSection<ManagerItem> = { type: 'cells', items: [] };
                const mcrSection: DataSection<ManagerItem> = { type: 'cells', items: [] };

                fetcherData?.forEach((item) => {
                    if (item.enum === ManagerEnum.Hiring) {
                        hiringSection.items.push({ value: item.value, data: item });
                    } else if (item.enum === ManagerEnum.NonMcr) {
                        nonMcrSection.items.push({ value: item.value, data: item });
                    } else {
                        mcrSection.items.push({ value: item.value, data: item });
                    }
                });

                if (hiringSection.items.length) {
                    sections.push({
                        type: 'delimiter',
                        text: trls[TrlKeys.hiringManager],
                    });
                    sections.push(hiringSection);
                }
                if (nonMcrSection.items.length) {
                    sections.push({
                        type: 'delimiter',
                        text: trls[TrlKeys.nonMcp],
                    });
                    sections.push(nonMcrSection);
                }

                if (mcrSection.items.length) {
                    sections.push({
                        type: 'delimiter',
                        text: trls[TrlKeys.mcp],
                    });
                    sections.push(mcrSection);
                }

                return sections;
            }),
        });

        return { items, provider };
    }, [activeManagers, trls, filterHiringManagers, hiringManagers, currentManagerId]);

    const onClose = useCallback(() => {
        const newVal = activeManagers.filter((manager) => value.includes(manager.id.toString()));
        setSelectedManagers(newVal);
        onChange(newVal.map((manager) => manager.id.toString()));
    }, [activeManagers, onChange, value, setSelectedManagers]);

    if (isLoading || !items.length) {
        return (
            <Card.Skeleton height={56} loading={isLoading} borderRadius={16} stretched>
                <Banner
                    stretched
                    showClose={false}
                    style="warning"
                    content={<Text typography="paragraph-2-regular">{trls[TrlKeys.noOptions]}</Text>}
                />
            </Card.Skeleton>
        );
    }

    return (
        <SelectFixedHeight multiple>
            <Select
                multiple
                type="checkbox"
                name={name}
                triggerProps={{
                    size: 'large',
                    label: trls[TrlKeys.selectPlaceholder],
                    disabled: isLoading,
                    invalid: isInvalid,
                    errorMessage: error,
                    description,
                    stretched: true,
                }}
                searchable
                dataProvider={provider}
                applyChangesButton={
                    <Button mode="primary" style="accent">
                        {trls[TrlKeys.applyButton]}
                    </Button>
                }
                onChange={(newValue) => onChange(newValue.map((item) => item.value))}
                onDropClose={onClose}
                onBottomSheetClose={onClose}
                value={items.filter((item) => value.includes(item.value))}
            />
        </SelectFixedHeight>
    );
};

export default translation(HiringManagerSuggest);
